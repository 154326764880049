#backgroundLeft,
#backgroundRight {
    background: none;
    position: absolute;
    image-rendering: pixelated;

    z-index: -100;

    height: max(50vw, 500px);
}

#backgroundLeft {
    left: -10%;

    top: 0%;
}

#backgroundRight {
    z-index: -101;

    right: -10%;
    bottom: 7%;
}

#root,
.App {
    height: 100%;
}

#main-window {
    height: 100%;

    display: flex;
    flex-direction: column;
}

#main-flex {
    display: flex;
    flex-direction: row;

    box-sizing: border-box;

    width: 100%;
    height: 100%;

    gap: var(--main-padding);
    padding: var(--main-padding);

    font-size: 2em;
}


#main {
    width: 0;
}

#main-window-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    height: 100%;
    gap: 1em;
}

#main-content {
    margin: 0em 4em 0em 4em;
}

.projectSection {
    /* max-width: 75%; */
    margin: auto;
}

.content-title {
    font-size: 3em;
    font-weight: bold;
    text-align: center;
    margin: 1em;
}

.projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1em;
}

.footer {
    margin: 1em;
    padding: 1em;
    text-align: center;
}


@media screen and (max-width: 769px) {
    #main-flex {
        flex-direction: column;
    }

    #main {
        width: auto !important;
    }

    .project-image {
        width: 100% !important;
        max-width: 100% !important;
    }
}

@media screen and (max-width: 540px) {
    #main-content {
        margin: 0 1em 0 1em;
    }

    .content-title {
        margin: 1em 0 1em 0;
    }
}