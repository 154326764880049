.project {
    position: relative;
    box-sizing: border-box;

    width: min(40vh, 100%);
    max-height: 540px;

    overflow: hidden;
    text-overflow: ellipsis;


    transform: scale(1) rotateZ(0);
    transition: transform 0.3s;
}

.project.outline {
    border: 2px white solid;
    box-sizing: border-box;
    border-radius: 1em;
}

.project-image {
    border-radius: 1em;
    width: 100%;

    opacity: 100%;
    transition: opacity 0.3s;
}

.center-title {
    width: 100%;
    height: 100%;

    text-align: center;
    font-size: 2em;
    transition: opacity 0.3s;

    color: white;

    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.project-content {
    position: absolute;
    top: 0;
    left: 0;
    margin: 1em;

    color: white;

    opacity: 0%;
    transition: opacity 0.3s;

    display: flex;
    flex-direction: column;
    gap: 1em;

    height: calc(100% - (1em * 2));
}

.project-title {
    font-size: 1.25em;
    font-weight: bold;
}

.project:hover {
    transform: scale(1.1) rotateZ(3deg);
}

.project:hover .project-image {
    opacity: 25%;
}

.project:hover .center-title {
    opacity: 0%;
}

.project:hover .project-content {
    opacity: 100%;
}

.project-description {
    font-size: 0.75em;
    font-weight: normal;
}

.read-more {
    position: absolute;
    bottom: 0;
    left: 0;
}